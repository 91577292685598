import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "../DashboardLayout/TopBar";
import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    // minHeight: "calc(100vh - 415px)",
  },

  root: {
    background: "url('images/lightbackground.png')",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  root1: {
    overflow: "hidden",
    position: "relative",
    background: "rgba(235,237,239)",
    height: "100vh",
    "& .MuiAppBar-positionFixed": {
      position: "unset !important",
    },
  },
  MainLayout: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
    overflowY: "auto",
  },
  contentContainer1: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);

  console.log("router", history);

  useEffect(() => {
    if (sessionStorage.getItem("adminToken")) {
      history.push("/dashboard");
    }
  }, [history]);

  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <TopBar />
      <div className={classes.contentContainer1}>
        <div className={classes.MainLayout}>{children}</div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
