import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaSquarespace } from "react-icons/fa";
import { AiFillHome, AiFillDashboard } from "react-icons/ai";
import { NavLink as RouterLink } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  Icon,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { FcManager } from "react-icons/fc";
import { BsFillPersonFill } from "react-icons/bs";
import { LiaAddressCardSolid } from "react-icons/lia";
import { TbReportMoney } from "react-icons/tb";
import { BiLockOpenAlt } from "react-icons/bi";
import { RiAdminFill } from "react-icons/ri";
import { MdReviews } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { BiSolidReport } from "react-icons/bi";
import { IoMdNotifications } from "react-icons/io";
import { MdSportsMotorsports } from "react-icons/md";
import CloseIcon from "@material-ui/icons/Close";
import { MdTaxiAlert } from "react-icons/md";
import { TbReservedLine } from "react-icons/tb";
import { BiSolidUserCircle } from "react-icons/bi";
import { RiHotelLine } from "react-icons/ri";
import { FaGifts } from "react-icons/fa6";
import { AuthContext } from "src/context/Auth";
import clsx from "clsx";
import toast from "react-hot-toast";

function renderNavItems({
  items,
  pathname,
  depth = 0,
  state,
  setSelectedTab,
  roleManagement,
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            state,
            setSelectedTab,
            roleManagement,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  state,
  setSelectedTab,
  roleManagement,
}) {
  const key = item.title + depth;

  const isActive = matchPath(pathname, {
    path: item.href,
    exact: false, // Set to false if you want sub-routes to be considered active
  });

  // if (item.items) {
  //   const open = matchPath(pathname, {
  //     path: item.href,
  //     exact: false,
  //   });

  if (item.items) {
    // Set 'open' to true if the current route or any sub-module is active
    const open =
      isActive ||
      item.items.some((subItem) => {
        const subItemPath = subItem.href;
        return matchPath(pathname, {
          path: subItemPath,
          exact: false, // Set to false if you want sub-routes to be considered active
        });
      });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          state,
          setSelectedTab,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        tabview={item.tabview}
        key={key}
        title={item.title}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: theme.palette.background.header,
    background: "linear-gradient(0deg, #00BED4 0.16%, #15D5EB 99.76%)",
  },
  desktopDrawer: {
    zIndex: "1",
    top: "76px",
    width: "250px",
    height: "calc(100% - 125px)",
    margin: "5px 10px 10px 15px",
    background: "linear-gradient(0deg, #00BED4 0.16%, #15D5EB 99.76%)",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "5px",
    left: "17px",
    fontWeight: "400",
    fontSize: "13px",
    background: "transparent",
    boxShadow: "none",
  },
  sideMenuBox: {
    // background:'#232B3B',
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      background: "white !important",
    },
  },
  logoutimgicon: {
    border: "1px solid white",
    background: "white",
    color: "red",
    width: "100px",
    height: "70px",
  },
  icon: {
    display: "flex",
    fontSize: "20px",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  typologout: {
    marginTop: "20px",
    color: "black",
  },
  noyesbtn: {
    padding: "8px 21px",
    background: "linear-gradient(0deg, #00BED4 0.16%, #15D5EB 99.76%)",
    border: "1px solid black",
    color: "white",
  },
  closeIconbtn: {
    position: "absolute",
    right: "16px",
    top: "0px",
    color: "black",
  },
  buttonLeaf: {
    color: theme.palette.primary.main,
    padding: "15px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    marginBottom: "5px",
    borderRadius: 0,
    opacity: 1,
    borderRadius: "0px",
    fontWeight: "400",
    fontSize: "12px",
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#0B1426",
      background: "#fff",
      borderRadius: "0px",
      "& $icon": {
        color: "#0B1426",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 400,
        fontSize: "13px",
        whiteSpace: "pre",
      },
    },
    "@media(max-width: 780px)": {
      padding: "13px 5px",
    },
  },
  titleActive: {
    color: "#0B1426",
    background: "#fff",
    // background: theme.palette.background.active,

    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: "12px",
    },
    "& $icon": {
      color: "#0B1426",
    },
  },
}));

const sections = [
  {
    title: "Dashboard",
    icon: <AiFillDashboard />,
    href: "/dashboard",
    tabview: "Arbitrage",
    roleTitle: "dashboard",
  },
  {
    title: "User Management",
    icon: <BiSolidUserCircle />,
    href: "/user-management",
    tabview: "Arbitrage",
    roleTitle: "userManagementPermission",
  },
  {
    title: "Sub-Admin Management",
    icon: <RiAdminFill />,
    href: "/sub-admin",
    tabview: "Arbitrage",
    roleTitle: "subAdminPermission",
  },
  {
    title: "Restaurant Management",
    icon: <RiHotelLine />,
    href: "/Restaurant-Listing",
    roleTitle: "restaurantPermission",
  },
  {
    title: "Reservation Management",
    icon: <TbReservedLine />,
    href: "/reservations-management",
    tabview: "Arbitrage",
    roleTitle: "reservationPermission",
  },

  {
    title: "Reward Management",
    icon: <FaGifts />,
    href: "/reward-list",
    tabview: "Arbitrage",
    roleTitle: "rewardPermission",
  },
  {
    title: "Content Management",
    icon: <BsFillPersonFill />,
    href: "/content-management",
    tabview: "Arbitrage",
    roleTitle: "staticPermission",
  },
  {
    title: "Notification Management",
    icon: <IoMdNotifications />,
    href: "/BroadCast-manage",
    tabview: "Arbitrage",
    roleTitle: "notificationPermission",
  },

  // {
  //   title: "Review & Ratings",
  //   icon: MdReviews,
  //   href: "/rating&review-management",
  //   tabview: "Arbitrage",
  // },
  {
    title: "Profile",
    icon: <BiLockOpenAlt />,
    href: "/profile",
    tabview: "Arbitrage",
    roleTitle: "profile",
  },
];
const sections1 = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillHome,
        href: "/sniper-dashboard",
        tabview: "Sniper",
      },
      {
        title: "Bot settings",
        icon: AiFillDashboard,
        href: "/bot-setting",
        tabview: "Sniper",
      },
      {
        title: "Transaction History",
        icon: FaSquarespace,
        href: "/sniper-transactions",
        tabview: "Sniper",
      },
    ],
  },
];
const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [roleManagement, setRoleManagement] = useState({
    subAdminPermission: true,
    userManagementPermission: true,
    reservationPermission: true,
    notificationPermission: true,
    staticPermission: true,
    restaurantPermission: true,
    rewardPermission: true,
    dashboard: true,
    profile: true,
  });
  const renderedSections = tabView === "Arbitrage" ? sections : sections1;

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth?.userData?.permissions?.length > 0) {
      const roleObj = {
        subAdminPermission: auth?.subAdminPermission?.read,
        userManagementPermission: auth?.userManagementPermission?.read,
        reservationPermission: auth?.reservationPermission?.read,
        notificationPermission: auth?.notificationPermission?.read,
        staticPermission: auth?.staticPermission?.read,
        restaurantPermission: auth?.restaurantPermission?.read,
        rewardPermission: auth?.rewardPermission?.read,
        dashboard: true,
        profile: true,
      };
      setRoleManagement(roleObj);
    }
  }, [auth?.userData]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {/* {renderedSections &&
              renderedSections?.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader
                      style={{
                        fontSize: "12px",
                      }}
                      disableGutters
                      disableSticky
                    >
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    img: section.img,
                    items: section.items,
                    pathname: location.pathname,
                    state: section.tabView,
                    setSelectedTab,
                    roleManagement: roleManagement,
                  })}
                </List>
              ))} */}
            {sections &&
              sections?.map((section, i) => {
                return (
                  <>
                    <Button
                      activeClassName={classes.active}
                      className={clsx(
                        classes.buttonLeaf,
                        `
                      ${
                        auth.pageTitle === section?.title && classes.titleActive
                      }
        `
                      )}
                      onClick={() => {
                        if (!roleManagement[section?.roleTitle]) {
                          toast.error("Not permitted");
                        } else {
                          history.push(section?.href);
                        }
                      }}
                    >
                      <span className={classes.icon}>{section.icon}</span>
                      <span className={classes.title}>{section.title}</span>
                    </Button>
                  </>
                );
              })}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setIsLogout(false)}
                  className={classes.closeIconbtn}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h4"
                  color="primary"
                  className={classes.typologout}
                >
                  LOGOUT
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.typologout}
                >
                  Are you sure do you want to Logout ?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} style={{ marginTop: "20px" }}>
                <Button
                  color="secondary"
                  className={classes.noyesbtn}
                  onClick={() => {
                    sessionStorage.removeItem("adminToken");
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  Yes
                </Button>
                &nbsp; &nbsp;
                <Button
                  className={classes.noyesbtn}
                  color="secondary"
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
