import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },

  // Dashboard
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
    ),
  },

  //User Management
  {
    exact: true,
    path: "/user-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/index")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser.js")
    ),
  },
  // {
  //   exact: true,
  //   path: "/Edit-user",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/UserManagement/EditUser")),
  // },
  {
    exact: true,
    path: "/Add-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/AddUser")
    ),
  },

  // Sub Admin
  {
    exact: true,
    path: "/sub-admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SubAdmin/index")),
  },
  {
    exact: true,
    path: "/Viewsub-admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/ViewSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/AddSub-admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/AddSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/EditSub-admin/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/EditSubAdmin")
    ),
  },

  //Notification Management
  {
    exact: true,
    path: "/BroadCast-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/index")
    ),
  },
  {
    exact: true,
    path: "/ViewbroadCast-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/ViewBroadcast")
    ),
  },
  {
    exact: true,
    path: "/Add-broadcast",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/AddBroadcast")
    ),
  },
  {
    exact: true,
    path: "/Edit-broadcast",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/EditBoadcast")
    ),
  },

  //Restaurant Management
  {
    exact: true,
    path: "/Restaurant-Listing",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RestaurantManagement/index")
    ),
  },
  {
    exact: true,
    path: "/ViewRest-data",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RestaurantManagement/ViewRest")
    ),
  },
  {
    exact: true,
    path: "/edit-restaurant/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RestaurantManagement/EditRestaurant.js")
    ),
  },
  {
    exact: true,
    path: "/Add-restaurant",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RestaurantManagement/AddRestuarant")
    ),
  },

  // Rating and review
  // {
  //   exact: true,
  //   path: "/rating&review-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Rating&Review/index")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/view-rating",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Rating&Review/ViewRating")
  //   ),
  // },

  //Reservations management
  {
    exact: true,
    path: "/reservations-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReservationManagement/index")
    ),
  },
  {
    exact: true,
    path: "/view-reservations",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReservationManagement/ViewReservation")
    ),
  },
  // {
  //   exact: true,
  //   path: "/Edit-reservations",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/ReservationManagement/Editreservation")
  //   ),
  // },

  // Static Content
  {
    exact: true,
    path: "/content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/ContentList")
    ),
  },

  {
    exact: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/ViewContent")
    ),
  },

  {
    exact: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/EditContent")
    ),
  },
  //Reward management
  {
    exact: true,
    path: "/reward-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardManagement/index.js")
    ),
  },
  {
    exact: true,
    path: "/manage-reward-token",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardManagement/manageRewardToken.js")
    ),
  },
  {
    exact: true,
    path: "/add-reward",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardManagement/AddReward.js")
    ),
  },
  {
    exact: true,
    path: "/view-reward",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardManagement/ViewReward.js")
    ),
  },

  // Profile
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },

  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
