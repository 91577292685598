import { Contract } from "@ethersproject/contracts";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function sortRiderID(add) {
  const sortAdd = `${add.slice(0, 4)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function sortAddressChar(add) {
  const sortAdd = `${add.slice(0, 50)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const permissionName = {
  userManagement: "User Management",
  subAdminManagement: "Sub Admin Management",
  staticManagement: "Static Management",
  restaurantManagement: "Restaurant Management",
  reservationManagement: "Reservation Management",
  rewardManagement: "Reward Management",
  notificationManagement: "Notification Management",
  // Profile: "Profile",
};
